import React from 'react';
import Layout from '../layout';
import impressum from '../data/impressum';

const ImpressumPage = () => (
  <Layout lang="de" title="Impressum" className="simple-page">
    <div className="simple-page-top-banner-container">
      <h1 className="simple-page-top-banner-title">Impressum</h1>
    </div>
    <div className="simple-page-content">
      <div dangerouslySetInnerHTML={{ __html: impressum }} />
    </div>
  </Layout>
);

export default ImpressumPage;
